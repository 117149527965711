import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dependencies-replacement"
    }}>{`Dependencies replacement`}</h1>
    <p>{`Replace an dependencies is a common requirement when you are developing a `}<strong parentName="p">{`monorepo`}</strong>{` project.
For this scenario, `}<strong parentName="p">{`Sigi`}</strong>{` provide `}<inlineCode parentName="p">{`InjectionProvidersContext`}</inlineCode>{` to replace dependencies.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-recipes-dependencies-replacement-tktun"
      }}>{`Dependencies replacement example`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-recipes-dependencies-replacement-tktun?expanddevtools=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.tsx&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-recipes-dependencies-replacement" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import "reflect-metadata";
import React from "react";
import { render } from "react-dom";
import { ClassProvider } from "@sigi/di";
import { useModule, InjectionProvidersContext } from "@sigi/react";
import { HttpErrorClient } from "./http-with-error.service";
import { HttpBetterClient } from "./http-better.service";

import { AppModule } from "./app.module";

const AppContainer = React.memo(({ appTitle }: { appTitle: string }) => {
  const [{ list }, dispatcher] = useModule(AppModule);

  const loading = !list ? <div>loading</div> : null;

  const title =
    list instanceof Error ? <h1>{list.message}</h1> : <h1>{appTitle}</h1>;

  const listNodes = Array.isArray(list)
    ? list.map((value) => <li key={value}>{value}</li>)
    : null;
  return (
    <div>
      {title}
      <button onClick={dispatcher.fetchList}>fetchList</button>
      <button onClick={dispatcher.cancel}>cancel</button>
      {loading}
      <ul>{listNodes}</ul>
    </div>
  );
});

function App() {
  const betterHttpProvider: ClassProvider<HttpErrorClient> = {
    provide: HttpErrorClient,
    useClass: HttpBetterClient,
  };
  return (
    <>
      <AppContainer appTitle="Always error" />
      <InjectionProvidersContext providers={[betterHttpProvider]}>
        <AppContainer appTitle="Better http client" />
      </InjectionProvidersContext>
    </>
  );
}

const rootElement = document.getElementById("app");
render(<App />, rootElement);
`}</code></pre>
    <h2 {...{
      "id": "warning️"
    }}>{`Warning⚠️`}</h2>
    <p>{`For performance consideration, `}<strong parentName="p">{`InjectionProvidersContext`}</strong>{` should be as static as possible. For example, in most of apps in `}<a parentName="p" {...{
        "href": "https://leetcode-cn.com/circle"
      }}>{`LeetCode CN`}</a>{`, we only put the `}<strong parentName="p">{`InjectionProvidersContext`}</strong>{` in `}<strong parentName="p">{`AppRoot`}</strong>{` level. You should never put `}<strong parentName="p">{`InjectionProvidersContext`}</strong>{` in a dynamic context:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const EnhancedLoggerProvider: ClassProvider<Logger> = {
  provide: Logger,
  useClass: EnhancedLoggerForItemDetail,
}

const Item = React.memo(({ item }: { item }: Item) => {
  // very bad
  return (
    <InjectionProvidersContext providers={[EnhancedLoggerProvider]}>
      <div key={item.id}>
        <ItemDetail detail={item.data.detailObject} />
      </div>
    </InjectionProvidersContext>
  )
})
`}</code></pre>
    <p>{`If you have the similar requirement: `}<strong parentName="p">{`Use different dependencies in Component level rather than application level`}</strong>{`, you should handle the different in the `}<strong parentName="p">{`EffectModule`}</strong>{` side. For example you can inject different dependencies in `}<strong parentName="p">{`EffectModule`}</strong>{`, and use different dependency depend on the `}<strong parentName="p">{`Payload`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      